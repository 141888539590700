import React, { useState } from 'react';
import { Heading, Modal } from '../../components';
import { richText } from '../../util/richText';
import { useIntl } from 'react-intl';
import css from './ListingPage.module.css';
import CertificateModalContent from '../../components/ListingCard/CertificateModalContent';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextCertificates = ({ publicData }) => {
  const intl = useIntl();

  const [showModal, setShowModal] = useState(false);
  const [imageData, setImageData] = useState({ imageVariants: null, imageId: null });
  const [scrollPosition, setScrollPosition] = useState(0);

  // Collect all certificate fields dynamically
  const certificateFields = Object.keys(publicData).filter(key => key.startsWith('certificate'));
  const docImageData = publicData?.docImageData || {};

  if (certificateFields.length === 0) {
    return null;
  }

  const handleOpenModal = (fieldId) => {
    return () => {
      setImageData({ imageVariants: docImageData[fieldId]?.urls, imageId: docImageData[fieldId]?.imageId });
      setShowModal(true);
      setScrollPosition(window.scrollY);
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setImageData({ imageVariants: null, imageId: null });

    window.scrollTo(0, scrollPosition);
  }

  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    console.log('Toggling ModalInMobile - currently:', componentId, scrollingDisabled);
  };

  return (
    <>
      <Modal
        id="CertificateModal"
        isOpen={showModal}
        onClose={handleCloseModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {
          imageData.imageVariants && <CertificateModalContent imageVariants={imageData.imageVariants} imageId={imageData.imageId} />
        }
      </Modal>
      <div className={css.sectionTextCertificatesContainer}>
        <Heading as="h3" rootClassName={css.sectionTextCertificatesHeading}>
          {intl.formatMessage({ id: 'ListingPage.certificatesTitle' })}
          <span
            className={css.styledText}>*These documents will be visible in the listings photos</span>
        </Heading>
        <ul className={css.sectionTextCertificatesList}>
          {certificateFields.map((field, index) => (
            <div key={index} className={css.certificatesDataWrapper}>
              <CertificateItem
                text={publicData[field]}
              />
              {
                !!publicData?.docImageData?.[field] && publicData?.docImageData[field]?.urls && (
                  <button
                    onClick={handleOpenModal(field)}
                    className={css.viewButton}
                    type="button"
                  >
                    View Certificate
                  </button>
                )
              }
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};

const CertificateItem = ({ text }) => {
  const intl = useIntl();
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    breakChars: '/',
  });

  return text ? (
    <li className={css.sectionTextCertificatesItem}>
      <p className={`${css.text} ${css.whiteText}`}>{content}</p>
    </li>
  ) : null;
};

export default SectionTextCertificates;
